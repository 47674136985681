import { useAdAccountStore, useBrandStore } from 'stores';
import { useProximaSDK } from 'hooks';
import { AdAccount } from 'types/stores/ad-account-store';
import { IntegrationType } from 'types/integrations';

const useTiktok = () => {
  const adAccountStore = useAdAccountStore();
  const { brand } = useBrandStore();
  const tiktokIntegrationApi = useProximaSDK('TiktokIntegrationApi');

  const retrieveTiktokAdAccountFromApi = async (brandId: string) => {
    try {
      const response = await tiktokIntegrationApi.getTiktokAdAccounts(
        { brandId },
        { skipErrorToaster: true },
      );

      if (response.status !== 200) {
        return null;
      }

      const adAccounts = (response.data || []) as AdAccount[];

      adAccountStore.updateAdAcountsList(adAccounts, IntegrationType.TikTok);

      const activeAdAccount = adAccounts.find((adAccount) => adAccount.status === 'active');

      return activeAdAccount ?? null;
    } catch (e) {
      return null;
    }
  };

  const getTiktokAdAccount = async (opts?: { refresh?: boolean; brandId: string }) => {
    const { refresh = false, brandId = brand.brandId } = opts ?? {};

    if (refresh) adAccountStore.clear();
    else {
      const currentActiveAdAccount = adAccountStore.getActiveAdAccountFromList(
        IntegrationType.TikTok,
      );

      if (currentActiveAdAccount) return currentActiveAdAccount;
    }
    const activeAdAccount = await retrieveTiktokAdAccountFromApi(brandId);

    if (activeAdAccount) adAccountStore.updateAdAccount(activeAdAccount, IntegrationType.TikTok);

    return activeAdAccount;
  };

  return {
    getTiktokAdAccount,
  };
};

export default useTiktok;
